body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  overflow: auto;
  font-variant: normal !important; -webkit-font-variant: normal !important; font-variant-ligatures: none !important; -webkit-font-variant-ligatures: none !important;
  font-family: 'Roboto', sans-serif;
  color:rgb(224, 224, 224);
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.notchedOutline {
  border-color: rgba(169, 169, 169, 0.5) !important;
}
.Mui-focused .notchedOutline {
  border-color: white !important;
}
.MuiOutlinedInput-root:hover:not(.Mui-focused) .notchedOutline {
  border-color: rgba(169, 169, 169, 0.5) !important;
}
.labelRoot {
  color: rgba(169, 169, 169, 0.5);
}
.labelRoot.Mui-focused {
  color: white !important;
}
.inputRoot {
  color: rgba(169, 169, 169, 0.5);
}
.inputRoot.Mui-focused {
  color: white !important;
}
